import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, FieldInputProps, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "@biz/lib/context/AuthContext";
import { envColor } from "@biz/lib/color";

type PasswordFormProps = {
  onClose: () => void;
};

export default function PasswordForm({ onClose }: PasswordFormProps) {
  const { loading, changePassword } = useAuthContext();

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required"),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (!changePassword) return;
        if (!values.currentPassword || !values.newPassword) return;
        changePassword(values.currentPassword, values.newPassword).then(() => {
          onClose();
        });
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Field name="currentPassword" type="password">
            {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
              <FormControl
                isInvalid={
                  form.touched.currentPassword && form.errors.currentPassword
                }
              >
                <FormLabel htmlFor="currentPassword">
                  現在のパスワード
                </FormLabel>
                <Input {...field} id="currentPassword" type={"password"} />
                {form.touched.currentPassword && (
                  <FormErrorMessage>
                    {form.errors.currentPassword}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          </Field>
          <Field name="newPassword" type="password">
            {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
              <FormControl
                mt={4}
                isInvalid={form.touched.newPassword && form.errors.newPassword}
              >
                <FormLabel htmlFor="newPassword">新しいパスワード</FormLabel>
                <Input {...field} id="newPassword" type={"password"} />
                {form.touched.newPassword && (
                  <FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
                )}
              </FormControl>
            )}
          </Field>
          <Field name="confirmPassword" type="password">
            {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
              <FormControl
                mt={4}
                isInvalid={
                  form.touched.confirmPassword && form.errors.confirmPassword
                }
              >
                <FormLabel htmlFor="confirmPassword">
                  新しいパスワード(確認)
                </FormLabel>
                <Input {...field} id="confirmPassword" type={"password"} />
                {form.touched.confirmPassword && (
                  <FormErrorMessage>
                    {form.errors.confirmPassword}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          </Field>
          <ButtonGroup py={2} mt={8} display={"flex"}>
            <Button
              variant={"ghost"}
              onClick={onClose}
              isDisabled={loading}
              flex={1}
            >
              Close
            </Button>
            <Button
              type="submit"
              colorScheme={envColor}
              flex={2}
              isLoading={loading}
              isDisabled={
                Object.keys(touched).length === 0 ||
                Object.keys(errors).length > 0
              }
            >
              Change Password
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}
