import { useCallback } from "react";
import { useAuthContext } from "@biz/lib/context/AuthContext";

type Method = "GET" | "POST" | "PUT" | "DELETE";

export const useApiClient = () => {
  const { currentUser } = useAuthContext();

  const request = useCallback(
    async (
      url: string,
      method: Method,
      body?: any
    ): Promise<any | undefined> => {
      if (!currentUser) return;
      const idToken = await currentUser?.getIdToken();
      if (["POST", "PUT"].includes(method) && !body) return;
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: ["POST", "PUT"].includes(method)
          ? JSON.stringify(body)
          : undefined,
      }).then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message);
        }
        return data;
      });
    },
    [currentUser]
  );

  return {
    request,
  };
};
