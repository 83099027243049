import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import { useToast } from "@chakra-ui/react";
import {
  EmailAuthProvider,
  User,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import FirebaseAdaptor from "@biz/lib/firebase/firebase";
import { FirebaseError } from "firebase/app";

type AuthContextProps = {
  loading?: boolean;
  currentUser?: User | null;
  signin?: (email: string, password: string) => void;
  signout?: () => Promise<void>;
  changePassword?: (
    currentPassword: string,
    newPassword: string
  ) => Promise<void>;
};

const AuthContext = createContext<AuthContextProps>({});

export const AuthProvider: React.FC<
  AuthContextProps & { children: React.ReactNode }
> = ({ children }) => {
  const router = useRouter();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const signinWithEmail = (email: string, password: string) => {
    if (currentUser) {
      toast({ status: "warning", title: "already logged in" });
      return;
    }
    setLoading(true);
    return signInWithEmailAndPassword(
      FirebaseAdaptor.instance.auth,
      email,
      password
    )
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          setCurrentUser(user);
        }
      })
      .catch((error: FirebaseError) => {
        toast({
          status: "error",
          title: `ログインに失敗しました (${error.code})`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signout = useCallback(async () => {
    return signOut(FirebaseAdaptor.instance.auth).then(() => {
      setCurrentUser(null);
      router.push("/auth/signin");
    });
  }, [router]);

  const changePassword = useCallback(
    async (currentPassword: string, newPassword: string) => {
      if (!currentUser) {
        toast({ status: "warning", title: "ログインしていません" });
        return;
      }
      setLoading(true);
      const credential = EmailAuthProvider.credential(
        currentUser.email!,
        currentPassword
      );
      return reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          return updatePassword(currentUser, newPassword);
        })
        .then(() => {
          toast({ status: "success", title: "パスワードを変更しました" });
        })
        .catch((error: FirebaseError) => {
          toast({
            status: "error",
            title: `パスワード変更に失敗しました (${error.code})`,
          });
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [currentUser, toast]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      FirebaseAdaptor.instance.auth,
      (user: User | null) => {
        console.log("onAuthStateChanged", user);
        if (user) {
          if (!user.tenantId) {
            setCurrentUser(user);
          } else {
            // tenantIdが null でない場合はログイン不可
            toast({ status: "warning", title: "不正なユーザーです" });
            signout();
          }
        } else {
          setCurrentUser(null);
          if (!router.pathname.startsWith("/auth")) {
            router.push("/auth/signin");
          }
        }
      }
    );
    return () => unsubscribe();
  }, [router, signout, toast]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser,
        signin: signinWithEmail,
        signout,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
