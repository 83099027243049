import { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import useSWR from "swr";
import { useToast } from "@chakra-ui/react";
import { Tenant, TenantInvitation, TenantUser } from "@biz/interfaces";
import { useAuthContext } from "@biz/lib/context/AuthContext";
import { useApiClient } from "@biz/lib/hooks/useApiClient";

type TenantContextProps = {
  // TenantList
  tenants?: Tenant[];
  loadingTenants?: boolean;
  createTenant?: (name: string, displayName: string) => Promise<any>;
  deleteTenant?: (tenantId: string) => Promise<any>;
  suspendTenant?: (tenantId: string) => Promise<any>;
  activateTenant?: (tenantId: string) => Promise<any>;
  mfaEnabledTenant?: (tenantId: string) => Promise<any>;
  // Tenant
  tenant?: Tenant;
  users?: TenantUser[];
  invitations?: TenantInvitation[];
  loadingTenant?: boolean;
  loadingUsers?: boolean;
  loadingInvitations?: boolean;
  deleteTenantUser?: (tenantId: string, uid: string) => Promise<any>;
  suspendTenantUser?: (tenantId: string, uid: string) => Promise<any>;
  activateTenatnUser?: (tenantId: string, uid: string) => Promise<any>;
  deleteTenantUserMfaConfig?: (tenantId: string, uid: string) => Promise<any>;
  createTenantInvitation?: (
    tenantId: string,
    email: string,
    displayName: string,
    role: string
  ) => Promise<any>;
  deleteTenantInvitation?: (tenantId: string, email: string) => Promise<any>;
  // Common
  updating?: boolean;
};

const TenantContext = createContext<TenantContextProps>({});

export const TenantProvider: React.FC<
  TenantContextProps & { children: React.ReactNode }
> = ({ children }) => {
  const toast = useToast();
  const router = useRouter();
  const query = router.query;
  const { currentUser } = useAuthContext();
  const { request } = useApiClient();
  const [tenantId, setTenantId] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (!router.isReady) return;
    const id = router.query.tenantId as string;
    if (!!id) {
      setTenantId(id);
    }
  }, [query, router]);

  const {
    data: tenants,
    isLoading: loadingTenants,
    mutate: mutateTenants,
  } = useSWR<Tenant[]>(
    !!currentUser ? "/api/tenants" : null,
    async (path: string) => {
      const data = await request(path, "GET");
      return data.tenants;
    }
  );

  const { data: tenant, isLoading: loadingTenant } = useSWR<Tenant>(
    !!currentUser && !!tenantId ? `/api/tenants/${tenantId}` : null,
    async (path: string): Promise<Tenant> => {
      const data = await request(path, "GET");
      return data.tenant;
    }
  );

  const {
    data: users,
    isLoading: loadingUsers,
    mutate: mutateUsers,
  } = useSWR<TenantUser[]>(
    !!currentUser && !!tenantId ? `/api/tenants/${tenantId}/users` : null,
    async (path: string): Promise<TenantUser[]> => {
      const data = await request(path, "GET");
      return data.users;
    }
  );

  const {
    data: invitations,
    isLoading: loadingInvitations,
    mutate: mutateInvitations,
  } = useSWR<TenantInvitation[]>(
    !!currentUser && !!tenantId ? `/api/tenants/${tenantId}/invitations` : null,
    async (path: string): Promise<TenantInvitation[]> => {
      const data = await request(path, "GET");
      return data.invitations;
    }
  );

  const createTenant = async (
    name: string,
    displayName: string
  ): Promise<any> => {
    setUpdating(true);
    return request(`/api/tenants`, "POST", {
      name,
      displayName,
    })
      .catch((e: Error) => {
        toast({ status: "error", title: `作成に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateTenants();
      });
  };

  const deleteTenant = async (tenantId: string): Promise<any> => {
    const result = prompt(
      "本当に削除しますか？\n(この操作は取り消せません)\n\n削除する場合は テナントID を入力してください"
    );
    if (result !== tenantId) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}`, "DELETE")
      .catch((e: Error) => {
        toast({ status: "error", title: `削除に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateTenants();
      });
  };

  const suspendTenant = async (tenantId: string): Promise<any> => {
    if (!confirm("本当に停止しますか？")) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}`, "PUT", { status: "suspended" })
      .catch((e: Error) => {
        toast({ status: "error", title: `停止に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateTenants();
      });
  };

  const activateTenant = async (tenantId: string): Promise<any> => {
    if (!confirm("本当に再開しますか？")) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}`, "PUT", { status: "active" })
      .catch((e: Error) => {
        toast({ status: "error", title: `再開に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateTenants();
      });
  };

  const mfaEnabledTenant = async (tenantId: string): Promise<any> => {
    setUpdating(true);

    return request(`/api/tenants/${tenantId}/mfa_enabled`, "PUT", {
      tenant_id: tenantId,
    })
      .catch((e: Error) =>
        toast({ status: "error", title: `有効化に失敗しました (${e.message})` })
      )
      .finally(() => {
        setUpdating(false);
        mutateTenants();
      });
  };

  const deleteTenantUser = async (
    tenantId: string,
    uid: string
  ): Promise<any> => {
    const result = prompt(
      "本当に削除しますか？\n(この操作は取り消せません)\n\n削除する場合は「UID」を入力してください"
    );
    if (result !== uid) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}/users/${uid}`, "DELETE")
      .catch((e: Error) => {
        toast({ status: "error", title: `削除に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateUsers();
      });
  };

  const suspendTenantUser = async (
    tenantId: string,
    uid: string
  ): Promise<any> => {
    if (!confirm("本当に停止しますか？")) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}/users/${uid}`, "PUT", {
      status: "suspended",
    })
      .catch((e: Error) => {
        toast({ status: "error", title: `停止に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateUsers();
      });
  };

  const activateTenatnUser = async (
    tenantId: string,
    uid: string
  ): Promise<any> => {
    if (!confirm("本当に再開しますか？")) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}/users/${uid}`, "PUT", {
      status: "active",
    })
      .catch((e: Error) => {
        toast({ status: "error", title: `再開に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateUsers();
      });
  };

  const deleteTenantUserMfaConfig = async (
    tenantId: string,
    uid: string
  ): Promise<any> => {
    if (!confirm("本当にリセットしますか？")) return Promise.reject();
    setUpdating(true);
    return request(`/api/tenants/${tenantId}/users/${uid}/mfa_config`, "DELETE")
      .catch((e: Error) => {
        toast({
          status: "error",
          title: `リセットに失敗しました (${e.message})`,
        });
      })
      .finally(() => {
        setUpdating(false);
        mutateUsers();
      });
  };

  const createTenantInvitation = async (
    tenantId: string,
    email: string,
    displayName: string,
    role: string
  ): Promise<any> => {
    setUpdating(true);
    return request(`/api/tenants/${tenantId}/invitations`, "POST", {
      email,
      displayName,
      role,
    })
      .catch((e: Error) => {
        toast({ status: "error", title: `招待に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateInvitations();
      });
  };

  const deleteTenantInvitation = async (
    tenantId: string,
    email: string
  ): Promise<any> => {
    if (!confirm("本当に削除しますか？")) return Promise.reject();
    setUpdating(true);
    return request(
      `/api/tenants/${tenantId}/invitations/${encodeURIComponent(email)}`,
      "DELETE"
    )
      .catch((e: Error) => {
        toast({ status: "error", title: `削除に失敗しました (${e.message})` });
      })
      .finally(() => {
        setUpdating(false);
        mutateInvitations();
      });
  };

  return (
    <TenantContext.Provider
      value={{
        // TenantList
        tenants,
        loadingTenants,
        createTenant,
        deleteTenant,
        suspendTenant,
        activateTenant,
        mfaEnabledTenant,
        // Tenant
        tenant,
        users,
        invitations,
        loadingTenant,
        loadingUsers,
        loadingInvitations,
        deleteTenantUser,
        suspendTenantUser,
        activateTenatnUser,
        deleteTenantUserMfaConfig,
        createTenantInvitation,
        deleteTenantInvitation,
        // Common
        updating,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export const useTenantContext = () => useContext(TenantContext);
