import { initializeApp, FirebaseApp, FirebaseOptions } from "firebase/app";
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from "firebase/storage";
import { getAuth, Auth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics, Analytics } from "firebase/analytics";

export default class FirebaseAdaptor {
  private static _instance: FirebaseAdaptor;
  private _app: FirebaseApp;
  private _db: Firestore;
  private _storage: FirebaseStorage;
  private _auth: Auth;
  private _analytics: Analytics;

  private constructor() {
    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    } as FirebaseOptions;
    this._app = initializeApp(firebaseConfig);
    this._db = getFirestore(this._app);
    this._storage = getStorage(this._app);
    this._auth = getAuth(this._app);
    this._analytics = getAnalytics(this._app);

    if (process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR === "true") {
      try {
        connectFirestoreEmulator(this._db, "localhost", 8080);
        connectStorageEmulator(this._storage, "localhost", 9199);
        connectAuthEmulator(this._auth, "http://localhost:9099");
      } catch (e) {
        console.warn(e);
      }
    }
  }

  public static get instance(): FirebaseAdaptor {
    if (!this._instance) {
      this._instance = new FirebaseAdaptor();
    }
    return this._instance;
  }

  public get db() {
    if (this._db) {
      return this._db;
    }
    throw new Error("Firestore is not initialized");
  }

  public get storage() {
    if (this._storage) {
      return this._storage;
    }
    throw new Error("Storage is not initialized");
  }

  public get auth() {
    if (this._auth) {
      return this._auth;
    }
    throw new Error("Auth is not initialized");
  }

  public getAuthForTenant(tenantId: string) {
    if (this._app) {
      const auth = getAuth(this._app);
      auth.tenantId = tenantId;
      return auth;
    }
    throw new Error("Auth is not initialized");
  }

  public get analytics() {
    if (this._analytics) {
      return this._analytics;
    }
    throw new Error("Analytics is not initialized");
  }
}
