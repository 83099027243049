import Link from "next/link";
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { MdLogout, MdInfoOutline, MdSecurity } from "react-icons/md";
import Avatar from "boring-avatars";
import { useAuthContext } from "@biz/lib/context/AuthContext";
import { envColor } from "@biz/lib/color";
import PasswordForm from "./PasswordForm";

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser, signout } = useAuthContext();

  const envName = process.env.NEXT_PUBLIC_VERCEL_ENV || "local";
  const shortSha =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.slice(0, 7) || "none";

  return (
    <>
      <HStack px={4} py={2} bgColor={`${envColor}.200`}>
        {!!currentUser && (
          <>
            <Link href="/">
              <Button variant={"ghost"}>Dashboard</Button>
            </Link>
            <Link href="/tenant">
              <Button variant={"ghost"}>Tenant</Button>
            </Link>
            <Spacer />
            <Menu>
              <MenuButton>
                <Avatar
                  name={currentUser.uid}
                  variant={"beam"}
                  colors={[
                    "#cc4a1e",
                    "#f2a104",
                    "#f2e85c",
                    "#5c9b9b",
                    "#0e2f44",
                  ]}
                />
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<Icon as={MdInfoOutline} />}
                >{`${envName}: ${shortSha}`}</MenuItem>
                <MenuDivider />
                <MenuItem icon={<Icon as={MdSecurity} />} onClick={onOpen}>
                  Change Password
                </MenuItem>
                <MenuItem icon={<Icon as={MdLogout} />} onClick={signout}>
                  Sign Out
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>パスワード変更</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PasswordForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
