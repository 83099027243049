import { Button, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { Field, FieldInputProps, Form, Formik } from "formik";
import { useAuthContext } from "@biz/lib/context/AuthContext";
import { envColor } from "@biz/lib/color";

export default function SignInForm() {
  const { loading, signin } = useAuthContext();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        if (!signin) return;
        if (!values.email || !values.password) return;
        signin(values.email, values.password);
      }}
    >
      <Form>
        <Field name="email" type="email">
          {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
            <FormControl isInvalid={form.errors.email && form.touched.email}>
              <FormLabel htmlFor="email">メールアドレス</FormLabel>
              <Input {...field} id="email" />
            </FormControl>
          )}
        </Field>
        <Field name="password" type="password">
          {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
            <FormControl
              mt={4}
              isInvalid={form.errors.password && form.touched.password}
            >
              <FormLabel htmlFor="password">パスワード</FormLabel>
              <Input {...field} id="password" type={"password"} />
            </FormControl>
          )}
        </Field>
        <Flex mt={8}>
          <Button
            type="submit"
            colorScheme={envColor}
            flex={1}
            isLoading={loading}
          >
            SignIn
          </Button>
        </Flex>
      </Form>
    </Formik>
  );
}
