import type { AppProps } from "next/app";
import { ChakraProvider, ToastProviderProps } from "@chakra-ui/react";
import Layout from "@biz/components/layout";
import { AuthProvider } from "@biz/lib/context/AuthContext";
import { TenantProvider } from "@biz/lib/context/TenantContext";
import SignInPage from "@biz/pages/auth/signin";

const toastOptions: ToastProviderProps = {
  defaultOptions: {
    position: "bottom-left",
    isClosable: true,
    duration: 3000,
  },
};

export default function App({ Component, pageProps }: AppProps) {
  const getContent = () => {
    if ([SignInPage.name].includes(Component.name)) {
      return <Component {...pageProps} />;
    } else {
      return (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      );
    }
  };

  return (
    <ChakraProvider toastOptions={toastOptions}>
      <AuthProvider>
        <TenantProvider>{getContent()}</TenantProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}
