import { Noto_Sans_JP } from "next/font/google";
import Header from "./header";

const nsj = Noto_Sans_JP({ subsets: ["latin"] });

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <main className={nsj.className}>{children}</main>
    </>
  );
}
