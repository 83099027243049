import { Box, Card, Container } from "@chakra-ui/react";
import SignInForm from "@biz/components/SignInForm";
import { useRouter } from "next/router";
import { useAuthContext } from "@biz/lib/context/AuthContext";
import { useEffect } from "react";

export default function SignInPage() {
  const router = useRouter();
  const { currentUser } = useAuthContext();

  useEffect(() => {
    if (currentUser) {
      router.push("/");
    }
  }, [currentUser, router]);

  return (
    <Box bgColor={"blackAlpha.50"} h={"100vh"}>
      <Container py={16}>
        <Card p={8} mx={"auto"} maxW={"sm"}>
          <SignInForm />
        </Card>
      </Container>
    </Box>
  );
}
